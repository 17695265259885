import * as React from "react";
import { Helmet } from "react-helmet";
import PageLayout from "../PageLayout";
import SectionLayout from "../SectionLayout";
import HowWeCanHelp from "../components/HowWeCanHelp";

const Disclaimer = () => {
    return (
        <PageLayout
            noButton
            title={"Disclaimer"}
            navSize={"sm:h-80 md:h-80 h-72"}
            titleStyles={"xl:mt-12 lg:mt-16 md:mt-12 sm:mt-20 mt-16"}
        >
            <Helmet>
                <title>EPG Solutions | Privacy Policy</title>
                <meta
                    name="description"
                    content="Contact us Privacy Policy Your privacy is important to us. It is EPG Solutions&#8217; policy to respect your privacy regarding any information we may collect from you across our website, http://www.epgsolutions.com.au, and other sites we own and operate. We only ask for personal information when we truly need it to provide a service to you."
                />
            </Helmet>
            <div className={"bg-epg-yellow h-5"} />
            <SectionLayout bgColor={"bg-epg-white"}>
                <p className={"py-10 leading-7 font-exo2 text-epg-black"}>
                   DISCLAIMER TO BE WRITTEN
                </p>

            </SectionLayout>
            <HowWeCanHelp />
        </PageLayout>
    );
};

export default Disclaimer;
